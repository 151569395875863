<template>
  <!--<div class="w-full h-full">
    <TresCanvas shadow alpha realistic>



<TresPerspectiveCamera :position="[0, -30, 1200]" />
<!~~ <OrbitControls /> ~~>

<TresDirectionalLight cast-shadow :position="[-1000,0,-1000]" :intensity="1" />
<TresDirectionalLight cast-shadow :position="[1000,0,-1000]" :intensity="1" />

<TresDirectionalLight cast-shadow :position="[0,3000,10]" :intensity="1" />
<TresDirectionalLight cast-shadow :position="[0,-3000,10]" :intensity="1" />


<!~~ <TresDirectionalLight cast-shadow :position="[0,1000,1000]" :intensity="1" /> ~~>
<!~~ <TresAmbientLight :intensity="1" /> ~~>

<Suspense v-if="bullModel">
  <primitive :object="bullModel" />
</Suspense>


<!~~ <TresPointLight :position="[0, 0, 1000]" :intensity="30" /> ~~>
<!~~ <TresSpotLight :position="[30, 30, 30]" :intensity="5" :angle="Math.PI / 3 " :penumbra="0.5" :decay="1" :distance="100" /> ~~>




</TresCanvas>
  </div>-->

  <div ref="threeCanvas" class="w-[300px] h-[300px]"></div>
</template>

<script setup>
// import { TresCanvas } from '@tresjs/core'
// import { useFBX, OrbitControls } from "@tresjs/cientos";
// import { useLoader } from "@tresjs/core";
// import { TextureLoader } from "three";


import { onMounted, ref } from 'vue';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';


const threeCanvas = ref(null);
let scene, camera, renderer, controls, model;

function init() {
  scene = new THREE.Scene();
  // scene.background = new THREE.Color(0xaaaaaa);

  camera = new THREE.PerspectiveCamera(75, 300 / 300, 0.1, 2000);
  camera.position.set(0, 0, 1000);

  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  renderer.setSize(300, 300);
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.shadowMap.enabled = true;
  threeCanvas.value.appendChild(renderer.domElement);

  // controls = new OrbitControls(camera, renderer.domElement);

  addLights();
  loadModel();
}

function addLights() {
  const directions = [
    { x: -1000, y: 0, z: -1000 },
    { x: 1000, y: 0, z: -1000 },
    { x: 0, y: 3000, z: 10 },
    { x: 0, y: -3000, z: 10 }
  ];
  directions.forEach(dir => {
    const light = new THREE.DirectionalLight(0xffffff, 1);
    light.position.set(dir.x, dir.y, dir.z);
    scene.add(light);
  });
}

async function loadModel() {
  const loader = new FBXLoader();
  model = await loader.loadAsync('/models/bull.fbx');
  const textureLoader = new THREE.TextureLoader();
  const texture = await textureLoader.loadAsync('/models/christmas_photo_studio_07_1k.png');
  model.traverse(child => {
    if (child.isMesh) {
      var material = new THREE.MeshStandardMaterial( {
        metalness: 1,   // between 0 and 1
        roughness: 0.5, // between 0 and 1
        envMap: texture,
        // color: 0xFFD700
        color: child.material.color
    } );
      // child.material.metalness = 1;
      // child.material.roughness = 1;
      // child.material.map = texture;
      child.material = material;

    }
  });
  scene.add(model);
}

function animate() {
  requestAnimationFrame(animate);
  // controls.update();
  // model.rotation.y += 0.005;

  if (model) {
    model.rotation.y += 0.005;
  }
  
  renderer.render(scene, camera);
}

onMounted(() => {
  init();
  animate();
});



// const bullModel = ref(null)


// onMounted(async () => {


//   const loadModel = async (meshName) => {

    
//     let model = await useFBX(`/models/${meshName}`);
    
//     let texture = await useLoader(TextureLoader, "/models/christmas_photo_studio_07_1k.png");

//     for (let child of model.children) {
//       child.material.map = texture;
//     }

//     // model.visible = false
//     console.clear()
//     console.log('model', model)

//     return markRaw(model.children[0]);
//   }

//   // let model = await useFBX(`/models/${meshName.value}`);
//   // const texture = await useLoader(TextureLoader, "/models/metro_noord_1k.png");

//   // for (let child of model.children) {
//   //   child.material.map = texture;
//   // }

//   bullModel.value = await loadModel("bull.fbx");

//   const raf = () => {
//     bullModel.value.rotation.y +=0.005;
    
//     requestAnimationFrame(() => {
//       raf();
//     });
//   }


//     raf()
// });
</script>

<style>

</style>